<template>
  <div>

    <b-card>
      <b-row>
        <b-col
          cols="12"
          md="6"
          class="d-flex align-items-center justify-content-start mb-1 mb-md-0"
        >
          <label>Показывать по</label>
          <v-select
            v-model="perPage"
            :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
            :options="perPageOptions"
            :clearable="false"
            class="per-page-selector d-inline-block mx-50"
          />
          <label>записей</label>
        </b-col>
        <b-col
          cols="12"
          md="6"
        >
          <div class="d-flex align-items-center justify-content-end">
            <b-form-input
              v-model="searchQuery"
              class="d-inline-block mr-1"
              placeholder="Поиск..."
            />
          </div>
        </b-col>
      </b-row>
    </b-card>
    <section id="knowledge-base-content">
      <b-row>
        <b-col
          v-for="item in products"
          :key="item.id"
          :xl="$store.state.app.windowWidth > 1800 ? 2 : 3"
          lg="3"
          md="4"
          sm="6"
        >
          <b-card>
            <div class="item-img text-center">
              <b-link :to="{ name: 'product-view', params: { id: item.id } }">
                <b-img
                  rounded
                  fluid
                  :src="item.file && item.file.path ? item.file.path : '/no-image.png'"
                />
              </b-link>
            </div>
            <div
              v-if="item.actionPrice && item.actionPrice.quantity"
              class="price-quantity"
            >
              <div class="d-flex align-items-left justify-content-end">
                <b-img :src="boxQuantity" />
                <div class="text-primary">
                  +{{ item.actionPrice.quantity.stepProfit }}
                </div>
                <div> - за {{ item.actionPrice.quantity.step }} шт.</div>
              </div>
            </div>
            <h5 class="item-name">
              <b-link
                class="text-body"
                :to="{ name: 'product-view', params: { id: item.id } }"
              >
                {{ item.name }}
              </b-link>
            </h5>
            <div class="item-property  text-muted font-small-3">
              <div class="item-property-label">
                Производитель
              </div>
              <div class="item-property-text">
                {{ item.brand }}
              </div>
              <div class="item-property-label">
                Артикул
              </div>
              <div class="item-property-text">
                {{ item.articul }}
              </div>
            </div>

            <div class="item-description">
              {{ item.description }}
            </div>
            <div class="d-flex align-items-left justify-content-end">
              <div
                v-if="item.actionPrice.bonus && item.actionPrice.bonus"
                class="price-old text-muted small"
              >
                <s>{{ item.actionPrice && item.actionPrice.standard && item.actionPrice.standard.profit ? item.actionPrice.standard.profit : '5' }}B</s>
              </div>
              <div class="price-main font-weight-bold">
                {{ item.actionPrice.bonus && item.actionPrice.bonus ? item.actionPrice.bonus.profit : item.actionPrice.standard && item.actionPrice.standard && item.actionPrice.standard.profit ? item.actionPrice.standard.profit : '5' }}B
              </div>
              <b-img
                v-if="item.actionPrice.bonus && item.actionPrice.bonus"
                :src="arrowPrice"
              />
              <b-img :src="homeBonus" />
            </div>
            <!-- <div
              v-if="item.actions && item.actions[0].type === 'quantity'"
              class="item-action text-primary mt-1"
            >
              <div class="text-right mr-1">
                за {{ item.actions[0].step }} шт
              </div>
              <div class="text-left">
                {{ item.actions[0].stepProfit }} бонусов
              </div>
            </div> -->
            <!-- <div
              v-if="item.actions && item.actions[0].type !== 'quantity'"
              class="item-action text-primary mt-1"
            >
              <div class="text-right mr-1">
                за 1 шт
              </div>
              <div class="text-left">
                {{ item.actions[0].profit }} бонусов
              </div>
            </div> -->
          </b-card>
        </b-col>

      </b-row>
    </section>
    <b-pagination
      v-model="currentPage"
      :total-rows="totalItems"
      :per-page="perPage"
      first-number
      last-number
      class="mb-1 mt-1 mt-sm-0"
      prev-class="prev-item"
      next-class="next-item"
    >
      <template #prev-text>
        <feather-icon
          icon="ChevronLeftIcon"
          size="18"
        />
      </template>
      <template #next-text>
        <feather-icon
          icon="ChevronRightIcon"
          size="18"
        />
      </template>
    </b-pagination>
  </div>
</template>

<script>
/* eslint-disable global-require */
import { ref, watch } from '@vue/composition-api'
import {
  BRow, BCol, BCard, BFormInput, BPagination, BImg, BLink,
} from 'bootstrap-vue'
import vSelect from 'vue-select'
import productRepository from '@/repository/productRepository'
import store from '@/store'

export default {
  components: {
    BRow,
    BCol,
    BCard,
    BFormInput,
    BPagination,
    BImg,
    BLink,
    vSelect,
  },
  props: {
    actionId: {
      type: Number,
      default: null,
    },
  },
  data() {
    return {
      arrowPrice: require('@/assets/images/price-group/arrow_price.svg'),
      boxQuantity: require('@/assets/images/price-group/box_quantity.svg'),
      homeBonus: require('@/assets/images/price-group/home_bonus.svg'),
    }
  },
  setup({ actionId }) {
    const mainProps = ref({
      blank: true,
      width: 1600,
      height: 1200,
    })

    const isLoading = ref(false)
    const products = ref([])
    const perPage = ref(12)
    const totalItems = ref(0)
    const currentPage = ref(1)
    const perPageOptions = [1, 12, 24, 48]
    const searchQuery = ref('')

    const fetchItems = () => new Promise(resolve => {
      isLoading.value = true
      const params = {
        query: searchQuery.value,
        page: currentPage.value,
        limit: perPage.value,
        actionActive: 1,
      }
      if (actionId) {
        params.actionId = actionId
      }

      productRepository.elementList(params)
        .then(({ items, count }) => {
          products.value = items.map(item => ({
            ...item,
          }))
          totalItems.value = count
          isLoading.value = false
          resolve()
        })
        .catch(error => {
          store.commit('app/SET_ERROR', error)
        })
    })

    watch([currentPage, perPage, searchQuery], () => {
      fetchItems().then()
    })

    fetchItems().then()

    return {
      isLoading, products, perPage, totalItems, currentPage, perPageOptions, searchQuery, mainProps,
    }
  },
}
</script>

<style lang="scss" scoped>
.item-property {
  overflow: hidden;
  display: grid;
  grid-template-columns: 1fr 1fr;
  font-weight: 500;
  text-align: left;
  .item-property-label {
    display: -webkit-box;
    -webkit-line-clamp: 1;
    -webkit-box-orient: vertical;
    overflow: hidden;
    margin-top: 0.6rem;
  }
  .item-property-text {
    display: -webkit-box;
    -webkit-line-clamp: 1;
    -webkit-box-orient: vertical;
    overflow: hidden;
    margin-top: 0.6rem;
  }
}

.item-action {
  overflow: hidden;
  display: grid;
  grid-template-columns: 1fr 1fr;
  font-weight: 700;
  .text-left {
    display: -webkit-box;
    -webkit-line-clamp: 1;
    -webkit-box-orient: vertical;
    overflow: hidden;
  }
}
.item-name a {
  display: -webkit-box;
  -webkit-line-clamp: 3;
  -webkit-box-orient: vertical;
  overflow: hidden;
}
.price-main{
  font-size: 24px;
}
.price-old{
  margin-top: 10px;
  margin-right: 10px;
}
.price-quantity {
  position: absolute;
  top: 1.0rem;
  right: 1.5rem;
  font-size: 16px;
  font-weight: 500;
}
.item-img{
  padding-top: 17px;
}
</style>
