<template>
  <div>
    <b-alert
      v-if="errorData"
      variant="danger"
      :show="!elementData"
    >
      <h4 class="alert-heading">
        Ошибка при извлечении данных
      </h4>
      <div class="alert-body">
        {{ errorData }} Вернитесь
        <b-link
          class="alert-link"
          :to="{ name: 'action-list'}"
        >
          к списку
        </b-link>
        акций.
      </div>
    </b-alert>

    <template v-if="elementData">
      <b-card>
        <b-row>
          <b-col cols="12">
            <div class="d-flex justify-content-start align-items-center mb-1">
              <div
                class="action-icon mr-2"
                :style="{ 'background-color': `#${ elementData && elementData.color && elementData.color.color ? elementData.color.color : '' }`}"
              >
                <feather-icon
                  icon="GithubIcon"
                  size="28"
                  :style="{ 'color': '#fff'}"
                />
              </div>
              <div class="h2">
                {{ elementData.title }}
              </div>
            </div>
          </b-col>
          <b-col cols="12">
            <div class="d-flex justify-content-start align-items-center mb-1">
              <feather-icon
                icon="CalendarIcon"
                class="mr-50"
                size="18"
              />
              <span class="font-weight-bold mr-1">c {{ formatDate(elementData.activeFrom) }} по  {{ formatDate(elementData.activeTo) }}</span>
              <div
                v-if="elementData.activePeriod"
                class="font-weight-bold text-success"
              >
                <feather-icon
                  icon="CheckCircleIcon"
                  class="text-success"
                  size="18"
                />
                Активна
              </div>
              <div
                v-else
                class="font-weight-bold text-danger"
              >
                <feather-icon
                  icon="SlashIcon"
                  class="text-danger"
                  size="18"
                />
                Не активна
              </div>
            </div>
          </b-col>
          <b-col
            v-if="elementData.type === 'quantity'"
            cols="12"
          >
            <div class="d-flex justify-content-start align-items-center mb-1">
              <feather-icon
                icon="ShoppingCartIcon"
                class="mr-50"
                size="18"
              />
              <span class="font-weight-bold">за продажу {{ elementData.step }} штук</span>
              <span
                class="font-weight-bold text-danger ml-1"
                style="font-size: 20px;"
              > {{ elementData.profit }}</span>
              <feather-icon
                icon="BoldIcon"
                class="text-danger"
                size="18"
              />
            </div>
          </b-col>
        </b-row>
        <b-row>
          <b-col cols="12">
            {{ elementData.description }}
          </b-col>
        </b-row>
      </b-card>
    </template>
    <product-list :action-id="actionId" />

  </div>
</template>

<script>
import router from '@/router'
import { ref } from '@vue/composition-api'
import {
  BRow, BCol, BAlert, BLink, BCard,
} from 'bootstrap-vue'
import ProductList from '@/components/action-product/ProductList.vue'
import { formatDate } from '@core/utils/filter'
import actionView from './actionViewAdmin'

export default {
  components: {
    BRow,
    BCol,
    BAlert,
    BLink,
    BCard,

    ProductList,
  },
  setup() {
    const actionId = ref(parseInt(router.currentRoute.params.id, 10))
    const {
      elementData,
      errorData,

      getElement,
    } = actionView()

    getElement(actionId.value).then()

    return {
      actionId, elementData, errorData, formatDate,
    }
  },
}
</script>

<style lang="scss" scoped>
.action-icon {
  display: inline-block;
  padding: 10px;
  border-radius: 15px;
}
</style>
